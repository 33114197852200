import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

type NavItem = { id: string; title: string; type: string; icon: string; children?: NavItem[]; open?: boolean; url?: string };

@Injectable({
   providedIn: 'root'
})
export class NavigationService {
   constructor(
      private authService: AuthService,
      private configService: ConfigService
   ) {}

   public navigationItems = combineLatest([this.authService.currentUser, this.authService.adminImpersonationActive]).pipe(
      map(([, impersonating]) => {
         return this.getNav({
            isOrgAdmin: this.authService.isOrgAdmin,
            isSuperAdmin: !!this.authService.isSuperAdmin,
            isImpersonating: !!impersonating?.isActive,
            isOnPremise: this.configService.environment.isOnPremise
         });
      })
   );

   private getNav({ isSuperAdmin, isImpersonating, isOrgAdmin, isOnPremise }): NavItem[] {
      const nav = [
         {
            id: 'users',
            title: 'Registered Users',
            type: 'item',
            icon: 'person',
            url: '/pages/users'
         },
         {
            id: 'pending-users',
            title: 'Pending Users',
            type: 'item',
            icon: 'person_outline',
            url: '/pages/pending-users'
         },

         (isOrgAdmin || isImpersonating) && {
            id: 'group',
            title: 'Groups',
            type: 'item',
            icon: 'people_outline',
            url: '/pages/group'
         },
         (isOrgAdmin || isImpersonating) && {
            id: 'invites',
            title: 'Invite Links',
            type: 'item',
            icon: 'link',
            url: '/pages/invites'
         },
         (isOrgAdmin || isImpersonating) && {
            id: 'alleo-rooms',
            title: 'Alleo Rooms',
            type: 'item',
            icon: 'meeting_room',
            url: '/pages/alleo-rooms'
         },

         isSuperAdmin && {
            id: 'guides',
            title: 'In-app Messages',
            type: 'item',
            icon: 'chat_bubble',
            url: '/pages/guides'
         },
         isSuperAdmin &&
            isImpersonating &&
            !isOnPremise && {
               id: 'third-party-connection',
               title: 'Third Party Connections',
               type: 'item',
               icon: 'apps',
               url: '/pages/third-party-connections'
            },

         (isOrgAdmin || isSuperAdmin) && {
            id: 'configurations',
            title: 'Configuration',
            type: 'item',
            icon: 'settings_system_daydream',
            url: '/pages/configurations'
         },
         (isSuperAdmin || (isOrgAdmin && isOnPremise)) && {
            id: 'capabilities',
            title: 'Capabilities',
            type: 'item',
            icon: 'settings',
            url: '/pages/capabilities'
         },
         isSuperAdmin &&
            !isImpersonating && {
               id: 'organization',
               title: 'Organizations',
               type: 'item',
               icon: 'home',
               url: '/pages/org'
            },
         isSuperAdmin &&
            !isImpersonating && {
               id: 'partner',
               title: 'Partners',
               type: 'item',
               icon: 'recent_actors',
               url: '/pages/partners'
            },
         isSuperAdmin &&
            !isOnPremise && {
               id: 'languages',
               title: 'Languages',
               type: 'item',
               icon: 'language',
               url: '/pages/languages'
            },
         isSuperAdmin &&
            !isImpersonating && {
               id: 'theme',
               title: 'Theme',
               type: 'item',
               icon: 'invert_colors',
               url: '/pages/themes/e60da558-4fb6-4a52-ad25-7c99553623f0'
            },
         isSuperAdmin &&
            !isImpersonating && {
               id: 'user-plans',
               title: 'User plans',
               type: 'item',
               icon: 'assignment_ind',
               url: '/pages/user-plans'
            },

         isOnPremise &&
            (isImpersonating || isOrgAdmin) && {
               id: 'ldap',
               title: 'LDAP Settings',
               type: 'item',
               icon: 'person_outline',
               url: '/pages/ldap-settings-list'
            },

         (isSuperAdmin || isOrgAdmin) && {
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            icon: 'insights',
            children: [
               {
                  id: 'analytics-home',
                  title: 'Dashboard',
                  type: 'item',
                  icon: 'insights',
                  url: '/pages/analytics/home'
               },
               {
                  id: 'analytics-extended',
                  title: 'Extended',
                  type: 'item',
                  icon: 'analytics',
                  url: '/pages/analytics/extended'
               },
               {
                  id: 'analytics-survey',
                  title: 'Survey',
                  type: 'item',
                  icon: 'mood',
                  url: '/pages/analytics/survey'
               },
               {
                  id: 'analytics-live',
                  title: 'Live',
                  type: 'item',
                  icon: 'scatter_plot',
                  url: '/pages/analytics/live'
               }
            ]
         },

         {
            id: 'alleo-support',
            title: 'Alleo Support',
            type: 'item',
            icon: 'contact_support',
            url: 'https://support.withalleo.com'
         }
      ].filter((v) => !!v);

      for (const item of nav) {
         if (item.children?.some((c) => c.url === window.location.pathname)) {
            (item as any).open = true;
         }
      }

      return nav;
   }
}
